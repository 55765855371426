import {useEffect, useState, useRef} from 'react';
import parse, { domToReact } from 'html-react-parser';
import './style.scss';


const Chat = ({user}) => {

	  // useState:
	  // 	HTML 
	  // 	メッセージ
	  // 	削除モード
	  // 	メッセージID 
	  // 	WebSocketの状態
	  const [htmlText,setHTML] = useState("<span>Loading...</span>");
	  const [msgText,setMsg] = useState("_");
	  const [delF,setDel] = useState("_");
	  const [msgId,setId] = useState("_");
	  const [socketReady,setSocketState] = useState(false);
	  const [backgroundColor,setBgColor] = useState("#3B6EA5");

	  // WebSocket
  	  const socket = useRef(null); 

	  // バックエンドのURL
	  const url = "/app/cgi/chat.cgi";

	  // 乱数(ユニークな変数)の生成
	  const D = new Date();
	  const rand = D.getHours() + D.getMinutes() + D.getSeconds() + D.getMillseconds;
	
	  // 自分の投稿を青色にするCSS
	  const style = `
	      <style>
		html{
			/*background-color: #E8F3FF;*/
			/*background-color: #e9f7ef;*/
			background-color: ${backgroundColor};
		}
		.box{
			padding: 0px;
			border-radius: 0px;
			border: 1px #a9a9a9 solid;
			color: #000000;
			background-color: #DFDEDC;
			height: 100vh;
		}
		@media screen and (max-width: 1024px) {
			.box{
				margin-top: 0px;
			}
		}
		.discription{
			padding: 15px;
		}
	        .${user}{
		  background-color: aliceblue;
		}
		table{
			width: 100%;
		}
	      </style>
	  `;

	　// html --> React時の置き換え
	  // 自分の投稿に削除ボタンを表示する
	  const options = {
		replace: ({attribs, children}) => {
			if(!attribs) return;
			if(attribs.class === 'delBtn'){
				const id = domToReact(children, options);
				return(
					id==0 ? (
						<td></td>
					):(
						<td>
							<button className="button is-danger is-outlined is-small delBtn" onClick={() => {
								const sendPostStr = "user=" + user + "&msg=_&del=1&msgId=" + id;
								del(sendPostStr);
							}}>
								<i className="fa fa-trash" aria-hidden="true"></i>
							</button>
						</td>
					)
				)
			}
		}
          };
	  
	  // 削除
	  const del = async(sendPostStr) => {
	      const res = await fetch(url,{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
              if(socketReady){ 
		      try{
			      if(user!="_"){
				socket.current.send('{"user":"'+user+'","mode":"chat"}');
			      }else{
				socket.current.send('{"user":"'+rand+'","mode":"chat"}');
			      }
		      }catch(error){
			      console.log(error);
		      }
	      }
	  }

	  // 送信
	  const send = async() => {
	      const sendPostStr = "user=" + user + "&msg=" + msgText + "&del=_&msgId=_";
	      const res = await fetch(url,{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
		     setMsg("_");
	      	     document.getElementById("textInput").value = "";
	      }
              if(socketReady){ 
		      try{
			      if(user!="_"){
				socket.current.send('{"user":"'+user+'","mode":"chat"}');
			      }else{
				socket.current.send('{"user":"'+rand+'","mode":"chat"}');
			      }
		      }catch(error){
			      console.log(error);
		      }
	      }
	  }

	  // 更新
	  const refresh = async() => {
	      const sendPostStr = "user="+user+"&msg=_&del=_&msgId=_";
	      const res = await fetch(url,{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
		     const data = await res.text();
		     setHTML(data);
	      }
	  }


	  // ページ読み込み時
	  useEffect(() => {
	    if(socketReady) return;

	    // ページタイトル
	    document.title = "ひとことチャット(?)";

	    if(!socketReady){
		    const socketURL = "wss://www.3naly.xyz/ws";
		    const ws  = new WebSocket(socketURL);
		    socket.current = ws;
		    console.log("socketReady:"+socketReady);
	    }

	    // WebSocketのEventListener
            socket.current.addEventListener("open", e => {
		    console.log("Connection Open");
	    	    setSocketState(true);
            });
            socket.current.addEventListener("close", e => {
		    console.log("Connection Closed");
	    	    setSocketState(false);
            });
            socket.current.addEventListener("message", e => {
		    const wsjson = JSON.parse(e.data);
		    try {
			    refresh();
		    }catch(error){
			    console.log(error);
		    }

            });
	    
	    // テーブルデータのFetch
	    const str = msgText;
	    const sendPostStr = "user=" + user + "&msg=" + str;
	    const fetchHTML = async() => {
	      const res = await fetch(url,{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
		     const data = await res.text();
		     setHTML(data);
	      }
	    }
	    fetchHTML();

	  },[]);

	return(
	    <div className="main">
	      {parse(style)}
	      <div className="container">
		<div className="box">
		<div style={{backgroundImage:"var(--nav-img)",height:"36px",paddingLeft:"15px",paddingTop:"6px",marginBottom:"0px",borderRadius:"0px"}}>
			<b className="title is-5 has-text-white" style={{verticalAlign:"middle"}}>
				ひとことチャット
			</b>
		</div>
		<div className="discription">
		 <span className="">毎日0時でリセットされるよーん</span>
		 <div style={{border:"1px #dfdfdf solid",marginBottom:"5px"}}></div>
		 <input type="text" className="input" style={{width: '100vw'}} id="textInput" placeholder="何か書いてね" onChange={(e) => setMsg(e.target.value)} />
		 <br />
		 <div className="mt-1 al-r" style={{display:'block'}}>
			 <button className="button is-primary is-outlined ml-1" onClick={ () => {
					 document.getElementById("textInput").value = "おはみずせ～(●´ω｀●)";
				 	 setMsg("おはみずせ～(●´ω｀●)");
			 	}
			 }>
				おはみずせ～(●´ω｀●)
			</button>
			<button className="button is-link ml-1" onClick={refresh}>
				<i className="fa fa-refresh" aria-hidden="true"></i>
			</button>
			<button className="button is-primary ml-1" onClick={send}>送信</button>
		 </div>
			 {parse(htmlText,options)}
		</div>
		</div>
	      </div>
	    </div>
	)

}

export default Chat;
