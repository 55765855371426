import {useEffect, useState, useRef} from 'react';
import {JitsiMeeting} from '@jitsi/react-sdk';
import { useLocation } from "react-router-dom";

const Meet = ({user}) => {
	
	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const name = query.get('name');
	const room = query.get('room');
	const preJoin = query.get('prejoin');
	const apiRef = useRef();
	const [ url, setUrl ] = useState("https://www.3naly.xyz/login?redirect="+window.location.href);
	const [ meetingRoom, setRoom ] = useState("");
	const [ preJoinConfig, setPreJoin ] = useState(true);
	const [ joinName, setName ] = useState("");
	const [ msg, setMsg ] = useState("");

	// Lineメッセージ更新
	const sendMsgLine = async(msg) => {
	      const sendPostStr = "MSG="+msg;
	      const res = await fetch('/app/cgi/notify_line.cgi',{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
		     const data = await res.text();
	      }
	  }

	// Mattermostメッセージ更新
	const sendMsgMm = async(msg) => {
	      const sendPostStr = "MSG="+msg;
	      const res = await fetch('/app/cgi/notify_mm.cgi',{method:'post',headers:{'content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
		     const data = await res.text();
	      }
	  }

	// ページ読み込み時
	useEffect(() => {
		setRoom(room);
		if(room==null){
			const now = new Date();
		        const year = now.getFullYear(); // 年
		        const month = String(now.getMonth() + 1).padStart(2, '0'); // 月（0から始まるため+1）
		        const day = String(now.getDate()).padStart(2, '0'); // 日
		        const hours = String(now.getHours()).padStart(2, '0'); // 時
		        const minutes = String(now.getMinutes()).padStart(2, '0'); // 分
		        const seconds = String(now.getSeconds()).padStart(2, '0'); // 秒
			setRoom(`${year}${month}${day}${hours}${minutes}${seconds}`);
			// sendMsgMm(`[Jitsi Meeting] 誰かが参加しようとしています。 https://meet.3naly.xyz/${year}${month}${day}${hours}${minutes}${seconds}`);
		}

		setName(name);
		if(name==null){
			setName("Guest");
		}
		if(user!="_"){
			setName(user);
		}

		if(preJoin=="false"){
			setPreJoin(false);
		}


	},[]);

	// 誰かが参加したとき
	const participantJoined = (e) => {
	}

	// 参加したとき
	const videoConferenceJoined = (e) => {
		if(e.displayName!="tabu"){
			sendMsgMm("[Jitsi Meeting] "+e.displayName+"さんが入室しました。 https://meet.3naly.xyz/"+e.roomName);
		}
	}

	// 退室したとき
	const videoConferenceLeft = (e) => {
	}

	const handleApiReady = (apiObj) => {
		apiRef.current = apiObj;
		apiRef.current.on('participantJoined', participantJoined);
		apiRef.current.on('videoConferenceJoined', videoConferenceJoined);
		apiRef.current.on('videoConferenceLeft', videoConferenceLeft);
	}

	return(
		<JitsiMeeting
		    domain = "meet.3naly.xyz"
		    roomName = {meetingRoom}
		    configOverwrite = {{
			startWithAudioMuted: true,
			startWithVideoMuted: false,
			disableModeratorIndicator: true,
			startScreenSharing: true,
			enableEmailInStats: false,
			prejoinPageEnabled: preJoinConfig,
			prejoinConfig: {
				enabled: preJoinConfig,
			},
			defaultLaunchInBrowserOnMobile: true,
			disableDeepLinking: true
		    }}
		    interfaceConfigOverwrite = {{
			DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
			MOBILE_APP_PROMO: false
		    }}
		    userInfo = {{
			displayName:  joinName!="" ? joinName : "Guest" 
		    }}
		    onApiReady = { (externalApi) => {
			handleApiReady(externalApi);
		    } }
		    getIFrameRef = { (iframeRef) => { 
			iframeRef.style.height = 'calc(100% - 5px)'; 
			if(iframeRef.clientWidth >= 1280){
				iframeRef.style.height = 'calc(100% - 45px)'; 
			}
			if(iframeRef.clientWidth >= 1740){
				iframeRef.style.height = 'calc(100% - 20px)'; 
			}
		    } }
		/>
	)

}

export default Meet;
