import {useEffect, useState, React} from 'react';
import { Link as ScrollLink, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const ScheduleTable = ({ data, onSlotClick }) => {

  const now = new Date();
  const today = now.toISOString().split('T')[0]; // YYYY-MM-DD形式で今日の日付を取得
  const oneHourAgo = new Date(now.getTime() - (60 * 60 * 1000));
  const oneHourAgoTime = `${oneHourAgo.getHours().toString().padStart(2, '0')}${oneHourAgo.getMinutes().toString().padStart(2, '0')}`;
  const y = now.getFullYear();
  const m = ("00" + (now.getMonth()+1)).slice(-2);
  const d = ("00" + (now.getDate())).slice(-2);
  const yyyymmdd = y  + m +  d;

  // 時刻フォーマットを揃える関数
  const formatTime = (timeStr) => {
    return new Date(
      `1970-01-01T${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)}:00`
    ).getTime();
  };

  // JSONデータから日付ヘッダーを抽出する
  const uniqueDates = [...new Set(data.cal.map(item => item.date_disp2))];
  
  // 時間帯（0時〜24時）を表示する行を作成
  const timeRows = Array.from({ length: 24 }, (_, i) => i); // 時間帯の行 0〜24時
  
  // 特定の日付と時間に対応するスロットが存在するかをチェックする関数
  const getSlot = (date, time) => {
    return data.cal.filter(
      (entry) => entry.date_disp2 === date 
	         && parseInt(entry.oclock) === time 
	         && entry.valid !== "1"  
	         && entry.date >= yyyymmdd
	         && !(entry.date == yyyymmdd && entry.time_to <= oneHourAgoTime)
    ).length;

  };

  // 予約可能な時間帯の一覧の表示
  const getTimes = (date , time) =>{
    const filteredCal = data.cal.filter(
      (entry) => entry.date_disp2 === date 
	         && parseInt(entry.oclock) === time 
	         && entry.valid !== "1"  
	         && entry.date >= yyyymmdd
	         && !(entry.date == yyyymmdd && entry.time_to <= oneHourAgoTime)
    );
    console.log("予約可能時間の一覧",date,time,filteredCal[0].date_disp2);
    return(
      <>
        <ul>
          {
            filteredCal.map((entry, index) => (
                <li className="is-size-6 btn_time" >{entry.time_from_disp} 〜 {entry.time_to_disp}</li> 
            ))
          }
        </ul>
      </>
    );
  };

  // クリックされたボタンに対応するcalの一覧をコンソールに出力する関数
  const handleClick = (date, time) => {
    const filteredCal = data.cal.filter(
      (entry) => entry.date_disp2 === date 
	         && parseInt(entry.oclock) === time 
	         && entry.valid !== "1"  
	         && entry.date >= yyyymmdd
	         && !(entry.date == yyyymmdd && entry.time_to <= oneHourAgoTime)
    );

    // console.log('クリックされた時間帯の予約一覧:', filteredCal);

    // 親コンポーネントに日付と時間を渡す
    onSlotClick({ date, time, filteredCal});

  };

  return (
    <div className="scroll_table mb-2" id="scroll-container">
      <table className="table">
        <colgroup>
          <col />
          {uniqueDates.map((_, index) => (
            <col key={index} />
          ))}
        </colgroup>
        <thead>
          <tr>
            <th className="fixed" rowSpan="2" style={{verticalAlign:"middle"}}>時間</th>
            {uniqueDates.map((date, index) => (
              <th rowSpan="1" className="th_date" key={index}>{date.split('(')[0]}</th> // 日付のみ抽出
            ))}
          </tr>
          <tr>
          {uniqueDates.map((date, index) => {
            const day = date.split('(')[1].replace(')', ''); // 曜日抽出
            let className = '';

            if (day === '土') {
                className = 'has-text-link';
            } else if (day === '日') {
                className = 'has-text-danger';
            }

            return (
                <th rowSpan="1" key={index} className={className}> 
                    {day}
                </th>
            );
        })}
          </tr>
        </thead>
        <tbody name="scr_top">
          {timeRows.map((time) => (
            <tr key={time}>
              <td className="fixed" name={`scr_${time}`}>{time}</td>
              {uniqueDates.map((date, index) => {
                const slot = getSlot(date, time);
                return (
                  <>
                    {slot > 0 ? (
                      <td key={index} className="is-primary">
                        <ScrollLink to="time_scroll2" smooth={true} delay={0} duration={800} offset={-20}>
                          <button className="button is-primary btn_table"
                            onClick={() => handleClick(date, time)} >
                            {/* {slot} */}
                            {getTimes(date,time)}
                          </button>
                        </ScrollLink>
                      </td>
                    ) : (
                      <td key={index}>
                        <div className="btn_empty"></div>
                      </td>
                    )}
                  </>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Table = ({data, onSlotClick}) => {

	// ページ読み込み時
	useEffect(() => {
    if(data.initialScroll){
      handleScroll(data.initialScroll);
    }
  },[]);

  const handleScroll = (n) => {
    const target = n;
    switch(target){
      case 0:
          scroller.scrollTo("scr_top", {
            duration: 800,  // スクロールの所要時間（ミリ秒）
            delay: 0,       // スクロール開始までの遅延（ミリ秒）
            smooth: "easeInOutQuart",  // スムーズスクロールの動き
            containerId: "scroll-container",  // スクロールさせるコンテナID
            offset: -100,    // スクロールの位置を調整するためのオフセット
          });
          break; 
      default:
          scroller.scrollTo(`scr_${target}`, {
            duration: 800,  // スクロールの所要時間（ミリ秒）
            delay: 0,       // スクロール開始までの遅延（ミリ秒）
            smooth: "easeInOutQuart",  // スムーズスクロールの動き
            containerId: "scroll-container",  // スクロールさせるコンテナID
            offset: -100,    // スクロールの位置を調整するためのオフセット
          });
          break; 
      }
  };

  return (
  <>
    <div>
        <div style={{marginLeft:"8px",marginBottom:"3px"}}>
          <button onClick={() => handleScroll(0)} className="button is-small is-light" style={{fontSize:"11px"}}>0時 〜 </button> &nbsp;
          <button onClick={() => handleScroll(5)} className="button is-small is-light" style={{fontSize:"11px"}}>6時 〜 </button> &nbsp;
          <button onClick={() => handleScroll(11)} className="button is-small is-light" style={{fontSize:"11px"}}>12時 〜 </button> &nbsp;
          <button onClick={() => handleScroll(18)} className="button is-small is-light" style={{fontSize:"11px"}}>19時 〜 </button> &nbsp;
        </div>
        <ScheduleTable data={data} onSlotClick={onSlotClick} />
    </div>
  </>
  );
};

export default Table;
