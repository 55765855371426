import {useEffect, useState, useRef, useContext, createContext} from 'react';

const Modal = ({sid,data}) => {

  // 予定の確定
  const commitSchedule = async() => {
		delete data.fetchedData.cal;
		// テスト時: 1 
		// スケジュールの登録がスキップされる
		data.test = '0';
		const jsonData = JSON.stringify(data);
		const url = "/app/cgi/appt_commit.cgi";
		const sendPostStr = "sid="+ sid + "&jsonData=" + jsonData;
		const res = await fetch(url,{method:'post',headers:{'Content-type':'application/x-www-htmlForm-urlencoded'},body:sendPostStr,credentials:'include'});
		if (res.status == "200" || res.statustext == "ok"){
				const resData = await res.text();
		}
  };

  const meetingInfo = () => {
	const pattern = data.method;
	switch(pattern){
		case "Jitsi Meet":	
		    // ミーティングURL
			data.meetingUrl = `https://meet.3naly.xyz/himawarichachacha_${sid}`;
			return (
				<>
						<br />
						<label className="confirm_label">ミーティングURL</label>
						<a href={`${data.meetingUrl}#userInfo.displayName=%22${encodeURI(data.name)}%22&config.startWithVideoMuted=true`} 
						   target="_blank" style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold", overflowWrap:"break-word"}}>
							{`${data.meetingUrl}#userInfo.displayName=%22${encodeURI(data.name)}%22&config.startWithVideoMuted=true`}
						</a>
						<br />
						<br />
						<span>URLをクリックし「ミーティングに参加」をクリックすると始まります。</span>
						<br />
						<span>3分前を目安にアクセスし、カメラやマイクのテストを行ってください。</span>
						<br />
						<br />
						<span>当日や事前の準備でお困りごとがございましたら遠慮なく下記の連絡先までお問い合わせお願いします。</span>
						<br />
						<br />
						<span>株式会社XX 代表・タブチトモヤ <br />tomoya823@gmail.com <br />TEL: 090-6871-6050</span>
						<br />
				</>
			);
		case "Zoom":	
		// ミーティングURL
		data.meetingUrl = "_";
		return (
			<>
			
			</>
		);
		case "Discord":	
		// ミーティングURL
		data.meetingUrl = "_";
		return (
			<>
			
			</>
		);
		case "その他":	
		// ミーティングURL
		data.meetingUrl = "_";
		return (
			<>
			
			</>
		);
	}


  };
		  
  // ページ読み込み時
  useEffect(() => {
		commitSchedule();
  },[]);


  return (
    <div className="modalWindow">
	<h4 className="mt-2 ml-2 mb-2">予約が完了しました。</h4>
	<div style={{margin:"30px"}}>
		<span>以下の日程とお名前で予約しました。</span>
		<br />
		<br />
		<label className="confirm_label">日程</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
				{ data.data != null ? (
					<>{data.data.date_disp2}&nbsp;{data.data.time_from_disp} ~ {data.data.time_to_disp}</>
				) : (
					<></>
				)}
		</span>
		<br />
		<label className="confirm_label">コミュニケーションの手段</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.method != null ? (
						<>{data.method}</>
					) : (
						<></>
					)}
		</span>
		{meetingInfo()}
		<br />
		<br />
		<label className="confirm_label has-text-weight-bold">予約した方の情報</label>
		<label className="confirm_label">お名前</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
						<>{data.name}</>
					) : (
						<></>
					)}
		</span>
		<br />
		<label className="confirm_label">メールアドレス</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
						<>{data.email}</>
					) : (
						<></>
					)}
		</span>
		<br />
		<label className="confirm_label">電話番号</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
							<>{data.tel}</>
						) : (
							<></>
					)}
		</span>
		<br />
		<label className="confirm_label">メッセージ</label>
		<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.message != null ? (
						<>{data.message}</>
					) : (
						<></>
					)}
		</span>
		<br />
	</div>
    </div>
  );
};

export default Modal;
