import {useRef, useEffect, useState} from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import Meet from './Meet'; 
import PDF from './PDF';
import Dialog from './Dialog';
import {switchContent,switchDesk} from './Exports';
import './style.scss';

const Main = ({user}) => {

	const [backgroundColor,setBgColor] = useState("#3B6EA5");
	const [windowVisibility,setVisibility] = useState("none");
	const [statusbarClass,setStatusClass] = useState("minimized");
	const [appStatus,setAppStatus] = useState("none");
	const [contentHTML,setHTML] = useState("");
        const [windowTitle,setTitle] = useState({title:"",iconUrl:""});
	const [time, setTime] = useState("　");

	const [dispDialog, setDialog] = useState(false);
	const [dispMode, setMode] = useState("0");

	const desk1 = useRef(null);

	const search = useLocation().search;
	const query = new URLSearchParams(search);

	const style = `
        @keyframes fadeIn {
          0% { opacity: 0.5; }
          100% { opacity: 1; }
        }

	html,body{
		background-color: ${backgroundColor};
		cursor: default;
	}

	body{
		/* padding: 0px; */
	}

	.start{
		width: 83px;
		text-align: center;
		font-size: 18px;
		box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1); 
		border-left: 1px #ffffff solid;
		border-top: 1px #ffffff solid;
		border-right: 1px #111111 solid;
		border-bottom: 1px #111111 solid;
		background-color: #dfdfdf;
		margin-bottom: 4px;
		margin-right: 5px;
	}

	.active{
		display: ${appStatus};
		padding: 2px;
		width: 200px;
		text-align:center;
		box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.4); 
		border-right: 1px #ffffff solid;
		border-bottom: 1px #ffffff solid;
		background-color: #dfdfdf;
		margin-bottom: 5px;
		margin-right: 3px;
	}

	.minimized{
		display: ${appStatus};
		padding: 2px;
		width: 200px;
		text-align:center;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.4); 
		border-left: 1px #ffffff solid;
		border-top: 1px #ffffff solid;
		background-color: #dfdfdf;
		margin-bottom: 5px;
		margin-right: 3px;
	}

	.box{
		display: ${windowVisibility};
		animation: fadeIn 0.3s forwards;
		padding: 0px;
		border-radius: 0px;
		border: 1px #a9a9a9 solid;
		color: #000000;
		background-color: #FFFFFF;
	}
	`;

	useEffect(() => {
    		setInterval(() => {
      			let d = new Date();
      			let hour = d.getHours().toString().padStart(2, '0');
      			let minute = d.getMinutes().toString().padStart(2, '0');
		        setTime(hour + ':' + minute);
    		}, 1000);
  	}, []);

	useEffect(() => {
		changeMode();
		if(query.has("w")){
			desk1.current.click();
		}
  	}, [dispMode]);

	  // 内容の取得
	  const fetchContent = async() => {
              const url = "/app/cgi/content.cgi"
	      const sendPostStr = "mode="+dispMode;
	      const res = await fetch(url,{method:'post',headers:{'Content-type':'application/x-www-form-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
	     		const data = await res.text();
		     	setHTML(data);
	      }
	  }

	  // 表示モードの切り替え
	  const changeMode = () =>{
	     switch(window.location.hostname){
		case "192.168.2.105" :
		case "www.3naly.xyz" :
			setMode("1");
		break;
		case "demo.3naly.xyz" :
			setMode("2");
		break;
		default:
			setMode("3");
	     }
	  }

	return(
	    <div className="main">
	      <style>
		      {style}
	      </style>
	      <div className="deskIco">
		      <div className="icon1" ref={desk1} onClick={ ()=>{ 
			setTitle({title:"Welcome to 3naly.xyz",iconUrl:"./pc.svg"});
			fetchContent();
			setAppStatus("inline-block");
			setVisibility("block");
			setStatusClass("active");
			}
		      }>
				<img src="./pc.svg" style={{height:"60px"}} /><br/>
				Welcome
		      </div>
		      <br />
		      <br />
		      { dispMode == "1" ? (
		      <>
		      <div className="icon1" onClick={ ()=>{ 
			setTitle({title:"Gallery",iconUrl:"./gallery.png"});
			setAppStatus("inline-block");
			setVisibility("block");
			setStatusClass("active");
			}
		      }>
				<img src="./gallery.png" style={{height:"60px"}} /><br/>
				Gallery
		      </div>
		      <br />
		      <br />
		      <div className="icon1" onClick={ ()=>{ 
			setTitle({title:"のーと",iconUrl:"./note.svg"});
			setAppStatus("inline-block");
			setVisibility("block");
			setStatusClass("active");
			}
		      }>
				<img src="./note.svg" style={{height:"60px"}} /><br/>
				のーと
		      </div>
		      <br />
		      <br />
		      <div className="icon1" onClick={ ()=>{ 
			setTitle({title:"Video Calling",iconUrl:"./meet.svg"});
			setAppStatus("inline-block");
			setVisibility("block");
			setStatusClass("active");
			}
		      }>
				<img src="./meet.svg" style={{height:"60px"}} /><br/>
				Video Calling
		      </div>
		      <br />
		      <br />
		      </>
		      ):(
		      <div></div>
		      )}
		      {
			user == "_" ?(
			<div></div>
			):(
			<>
			      <div className="icon1" onClick={ ()=>{ 
				setTitle({title:"PDF Viewer & Presenter Tool",iconUrl:"/app/img/pdf.png"});
				setAppStatus("inline-block");
				setVisibility("block");
				setStatusClass("active");
				}
			      }>
					<img src="https://www.3naly.xyz/app/img/pdf.png" style={{height:"60px"}} />
					<br/>
					PDF Viewer & Presenter Tool
			      </div>
			</>
			)
		      }
	      </div>
	      <div className="container">
		{dispDialog ? (
			<Dialog 
				user={user}
				name="React App"
				title="バージョン情報"
				iconurl="/pc.svg"
				winDisp="block"
				appDisp="block"
				statusBarCls="active"
				width="90%"
				height="40vh"
				leftpos="5%"
				toppos="25vh"
			>
				<div className="dialogContents">
					{ dispMode == "1" ? (
					<>
					BuildDate: 20240916201031 <br /><br />
					製作・著作: みなわ (@370kum) <br />
					イラスト: ちゃんかな(@Gy5qJxf5g8a7yTU)
					</>
					):(
					<>
					BuildDate: 20240916201031 <br /><br />
					製作・著作: Tomoya Tabuchi <br />
					</>	
					)}
				</div>
				<div className="dialogBtnArea" style={{textAlign:"center"}}>
					<button className="button winBtn" onClick={ ()=>{
						setDialog(false);
					}}>
						OK
					</button>
				</div>
			</Dialog>
		) : (
			<div></div>
		)}
			<div className="box">
				<div className="titleBar" >
					<div className="titleImg">
						<img src={windowTitle.iconUrl} style={{height:"22px"}} />
					</div>
					<div className="titleText">
						<b className="title is-5 has-text-white">
							{windowTitle.title}
						</b>
					</div>
					<div className="batsu" onClick={() => {
						setAppStatus("none");
						setVisibility("none");
					}}>
					</div>
				</div>
				<div className="discription">
				      {windowTitle.title === 'Welcome to 3naly.xyz' ? (
					parse(contentHTML)
				      ) : windowTitle.title === 'Gallery' ? (
					<iframe className="ifrmain" scrolling="no" src="https://photo.3naly.xyz/"></iframe>
				      ) : windowTitle.title === 'のーと' ? (
					<iframe className="ifrmain" scrolling="yes" src="https://note.3naly.xyz/"></iframe>
				      ) : windowTitle.title === 'Video Calling' ? (
					<div className="ifrmain">
						<Meet/>
					</div>
				      ) : windowTitle.title === 'PDF Viewer & Presenter Tool' ? (
					<div className="ifrmain">
						<PDF/>
					</div>
				      ) : (
					<div></div>
				      )}
				</div>
			</div>
	        </div>
	        <div className="footbar">
			<div style={{display:"flex"}}>
				<div className="start" onClick={ ()=>{ 
						if(appStatus=="none"){
							setTitle({title:"Welcome to 3naly.xyz",iconUrl:"./pc.svg"});
							fetchContent();
							setAppStatus("inline-block");
							setVisibility("block");
							setStatusClass("active");
						}
					}
				}>
					<div style={{display:"inline"}}>
						<p style={{display:"table-cell",verticalAlign:"middle"}}>
							<img src="./melon.svg" width="22px" style={{marginTop: "3px"}} />
							&nbsp;
						</p>
						<p style={{display:"table-cell"}}>
							<span>Start</span>
						</p>
					</div>
				</div>
				<div className={statusbarClass} onClick={ ()=>{ 
						if(windowVisibility=="block"){
							setVisibility("none");
							setStatusClass("minimized");
						}else{
							setVisibility("block");
							setStatusClass("active");
						}
					}
				}>
					<div style={{display:"inline"}}>
						<p style={{display:"table-cell",verticalAlign:"middle"}}>
							<img src={windowTitle.iconUrl} width="20px" style={{marginTop:"3px"}} />
							&nbsp;
						</p>
						<p style={{display:"table-cell"}}>
							{windowTitle.title}
						</p>
					</div>
				</div>
			</div>
			<div>
				<div className="clock" onClick={ ()=>{
					setDialog(true);
				}}>
					{time}
				</div>
			</div>
	        </div>
	    </div>
	)

}

export default Main;
