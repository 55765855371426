import {useEffect, useState} from 'react';

const Win = ({user,name,title,iconurl,winDisp,statusBarCls,appDisp,children,width,height,marginTop}) => {

	const [backgroundColor,setBgColor] = useState("#3B6EA5");
	const [windowDisplay,setWindowDisplay] = useState(winDisp);
	const [statusbarClass,setStatusClass] = useState(statusBarCls);
	const [appDisplay,setAppDisplay] = useState(appDisp);
	const [time, setTime] = useState([]);

	const style = `
	html,body{
		background-color: ${backgroundColor};
		cursor: default;
	}
	
	.box{
		padding: 0px;
		border-radius: 0px;
		border: 1px #a9a9a9 solid;
		color: #000000;
		background-color: #DFDEDC;
		margin: 0 auto;
		margin-top: ${marginTop};
		width: ${width};
		height: ${height};
	}

	.box1{
		display: ${windowDisplay};
		box-shadow: none;
	}

	.contents{
		padding: 15px;
	}
	`;

	useEffect(() => {
    		setInterval(() => {
      			let d = new Date();
      			let hour = d.getHours().toString().padStart(2, '0');
      			let minute = d.getMinutes().toString().padStart(2, '0');
		        setTime(hour + ':' + minute);
    		}, 1000);
  	}, []);

	return(
	    <div className="main">
	      <style>
			{style}
	      </style>
		<div className="box box1">
			<div className="titleBar" >
				<div className="titleImg">
					<img src={iconurl} style={{height:"22px"}} alt="" />
				</div>
				<div className="titleText">
					<b className="title is-5 has-text-white">
						{title}
					</b>
				</div>
				<div className="batsu" onClick={() => {
					setAppDisplay("none");
					setWindowDisplay("none");
				}}>
				</div>
			</div>
			<div className="contents">
				{children}
			</div>
		</div>
	      </div>
	)

}

export default Win;
