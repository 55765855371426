import { useEffect, useState, useRef, useContext, createContext } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import Main from './Appt/Main.js';

const Appt = ({ user }) => {

	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const sid = query.get('sid');
	const name = query.get('name') || "";
	const method = query.get('method') || "";

	const css = `
		@keyframes fadeIn {
		  0% { opacity: 0.5; }
		  100% { opacity: 1; }
		}

		.fade-in {
		  animation: fadeIn 0.3s forwards;
		}

		html{
		   overflow-y: scroll;
		   transform: scale(1.0);
		   height: 100%;
		   width: 100%;
		   animation: fadeIn 0.3s forwards;
		}

		body{
			padding: 15px;
			background-color: #eaeaea;
		}

		table{
			margin: 30px 0px 0px 0px;
			width: 48vw;
			margin-top: 0px;
			border-radius: 3px !important;
		}

		thead{
			position: sticky;
			top: 0;
			z-index: 100;
		}

		thead th{
			text-align: center !important;
			background-color: #e1e1e1;
			padding: 3px !important;
			border-bottom: 0px #fff solid !important;
		}

		thead tr{
		}

		tbody tr{
			height: 60px;
			border-bottom: 2px #dfdfdf dashed;
		}

		tbody tr>td{
			text-align: center !important;
			vertical-align: middle !important;
			padding:0 !important;
			margin:0 !important;
			border-right: 1px #dfdfdf solid;
			background-color: whitesmoke;
		}

		.scroll_table{
			overflow-y: scroll;
			height: 50vh;
			width: 49vw;
			margin-top: 0px;
			margin-left: 8px;
		}

		.fixed{
			position: sticky;
			left: 0;
			z-index: 99;
			background-color: #dbeeff;
			border-bottom: 2px #eaf5ff solid !important;
			width: 3vw;
		}

		.th_date{
			width: 178px !important;
		}

		.btn_table{
			border-radius: 0px;
			width: 100%;
			min-height:80px;
			max-height:160px;
			height: auto;
			flex-wrap: wrap;
			gap: 5px;
			padding: 0;
			text-align: center;
		}

		.btn_empty{
			border-radius: 0px;
			width: 100px;
			height:80px;
			padding: 0;
			text-align: center;
		}

		.btn_confirm{
			width: 45%;
			margin: 0 auto;
			height: 40px;
			margin-bottom: 5px;
			overflow-wrap: break-word;
		}

		.question{
			width: 400px;
		}

		.iptlabel{
			width: 210px !important;
			display: inline-block !important;
			margin: 8px 0px;
		}

		.confirmArea{
			display: flex;
			margin: -15px;
			width: 100vw;
			height:80px;
			padding-top: 20px;
			text-align: center;
			background-color:rgb(0,0,0,0.4);	
			position: sticky;			
			bottom: 0;				
			z-index: 1000;				
		}

		.confirm_label{
			display: block;
			margin-top: 20px;
			border-bottom: 1px #dfdfdf solid;
		}

		header{
			height: 47px;
			margin: -15px;
			padding: 10px;
			position: sticky;			
			top: 0;
			z-index: 1000;				
			background-color: #3a6ea5 !important;
		}

		.selectTime{
			margin: 15px 15px 15px 15px;
			display: flex;
			gap:3px;
			flex-wrap: wrap;
		}

		.selectTime > button{
			width: 220px;
		}

		.overlay{
			background-color: black;
			opacity: 1.0;

			overflow: hidden;

			z-index: 1001;
		}

		.timeSelect{
			padding-top: 10px;
			margin:0px 5px 40px 8px;
		}

		.modalWindow{
			height: 160vh;
			background-color: #eaeaea;
		}

		@media screen and (max-width: 1280px) {
			table{
				width: 100vw;
				margin-top: 0px;
			}
			.fixed{
				width: 5vw;
			}		
			.th_date{
			}
			.scroll_table{
				width: 90vw;
				height: 50vh;
				margin-top: 0px;
			}
			.timeSelect{
				padding-top:40px;
			}
			.btn_empty{
				width: 100px;
			}
		}
		.boxshadow{
			box-shadow: 0px 3px 2px 0px #c1bebe;
		}
	  `;

	// ページ読み込み時
	useEffect(() => {
		document.title = "調整ちゃん";
	}, []);


	return (
		<div>
			<style>{css}</style>
			{!sid ? (
				<div style={{ height: "100vh" }}>
					<header className="mb-5">
						<h3 className="title is-4 has-text-white"><a href="/appt" style={{ textDecoration: "none", color: "#FFF" }}>調整ちゃん</a></h3>
					</header>
					<div>
						SIDがありません。
					</div>
				</div>
			) : (
				<Main user={user} sid={sid} name={name} method={method} />
			)}
		</div>
	)

}

export default Appt;
