import {useEffect, useState, useRef} from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import Modal from './Modal.js';

const Confirm = ({user,sid,data}) => {

	  // ページ読み込み時
	  useEffect(() => {
	  },[]);


	return(
		<div style={{height:"100vh"}}>
			<h4 className="mt-2 ml-2 mb-2">予約内容の確認</h4>
			<div style={{margin:"30px"}}>
				<span>予約内容を確認してください。「予約を完了する」を押すと予約完了です。</span>
				<br />
				<label className="confirm_label">日程</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
				{ data.data != null ? (
					<>{data.data.date_disp2}&nbsp;{data.data.time_from_disp} ~ {data.data.time_to_disp}</>
				) : (
					<></>
				)}
				</span>
				<br />
				<label className="confirm_label">お名前</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
						<>{data.name}</>
					) : (
						<></>
					)}
				</span>
				<br />
				<label className="confirm_label">メールアドレス</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
						<>{data.email}</>
					) : (
						<></>
					)}
				</span>
				<br />
				<label className="confirm_label">電話番号</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.name != null ? (
							<>{data.tel}</>
						) : (
							<></>
					)}
				</span>
				<br />
				<label className="confirm_label">コミュニケーションの手段</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.method != null ? (
						<>{data.method}</>
					) : (
						<></>
					)}
				</span>
				<br />
				<label className="confirm_label">メッセージ</label>
				<span style={{marginRight:"3px",fontSize:"19px",fontWeight:"bold"}}>
					{ data.message != null ? (
						<>{data.message}</>
					) : (
						<></>
					)}
				</span>
				<br />
			</div>
		</div>
	)

}

export default Confirm;
