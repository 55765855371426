import {useLayoutEffect, useState, useRef} from 'react';
import {BrowserRouter,Routes,Route,Link,Redirect} from "react-router-dom";
import 'bulma/css/bulma.css';
import Main from './Main';
import Appt from './Appt.js';
import Chat from './Chat';
import PDF from './PDF';
import Win from './Win';

const postURL = (url,formId) => {
	var form = document.getElementById(formId);
	form.method = 'POST';
	form.target = '_self';
	form.action = url;
	form.submit();
}

function App() {

  // セッションチェック
  const [authStatus,setAuth] = useState({"statusText":"NG","user":"_"});
  const [isInit, setInit] = useState(false);

  useLayoutEffect(() => {
    const sesCheck = async() => {
      const url = "/app/cgi/verify_session.cgi";
      try{
	      const res = await fetch(url,{
		method:'POST',
		headers:{'Content-type':'application/x-www-form-urlencoded'},
		body:"_",
		credentials:'include'
	      });

	      if (res.status === 200 || res.statusText === "OK"){
		     const data = await res.json();
		     setAuth(data);
		     setInit(true);
	      }else{
		     console.log("Unexpected response status:", res.status);
	      }
     }catch(error){
	      console.log("Fetch error:",error);
     }
    }
    sesCheck();
  },[]);
 
  return (
     !isInit ? (
      <div style={{backgroundColor:"#3B6EA5"}}></div>
     ) : (
      <BrowserRouter>
      <Routes>
	      <Route path="/" element={
		      <div>
			      <Main user={authStatus.user} />
		      </div>
	       }  
	      />
	      <Route path="/chat" element={
		      authStatus.user == "_" ? (
		      	<Win 
				user={authStatus.user}
				name="React App"
				title="ログインが必要です"
				iconurl="/logo512.png"
		      		winDisp="false"
		      		appDisp="block"
		      		statusBarCls="active"
		      		width="30vw"
		      		height="13vh"
				marginTop="34vh"
		      	>
			        <a href="/login">ログイン</a>してください。
		      	</Win>
		      ):( 
		        <div>
				<Chat user={authStatus.user} />
		        </div>
		      )
	       }  
	      />
	      <Route path="/okane" element={
		      authStatus.user == "_" ? (
		      	<Win 
				user={authStatus.user}
				name="React App"
				title="ログインが必要です"
				iconurl="/logo512.png"
		      		winDisp="block"
		      		appDisp="block"
		      		statusBarCls="active"
		      		width="30vw"
		      		height="13vh"
				marginTop="34vh;"
		      	>
			        <a href="/login">ログイン</a>してください。
		      	</Win>
		      ):( 
		        <div>
		        </div>
		      )
	       }  
	      />
	      <Route path="/pdf" element={
		      <div>
			 <PDF user={authStatus.user} />
		      </div>
	       }  
	      />
	      <Route path="/appt" element={
		      <div>
			 <Appt user={authStatus.user} />
		      </div>
	       }  
	      />
	      <Route path="/*" element={
		      <div>
			      <Main user={authStatus.user} />
		      </div>
	       }  
	      />
      </Routes>
    </BrowserRouter>
    )
  );
}
   

export default App;
