import { useEffect, useState, useRef } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import './style.scss';

const PDF = ({ user }) => {

	const search = useLocation().search;
	const query = new URLSearchParams(search);
	const file = query.get('file');
	const mode = query.get('mode');

	const Path1 = "/app/pdf/viewer/viewer1.html?file=../rcv/" + file;
	const Path2 = "/app/pdf/viewer/viewer2.html?file=../rcv/" + file;
	const Path3 = "/app/pdf/viewer/viewer3.html?file=../rcv/" + file;

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPage] = useState(1);
	const [textHtml, setText] = useState("<div></div>");
	const [pdfListHtml, setPdfList] = useState("<div></div>");
	const [backgroundColor, setBgColor] = useState("#eaeaea");

	// WebSocket
	const [socketReady, setSocketState] = useState(false);
	const socket = useRef(null);

	const pdfmain = `
		html{
			background-color: ${backgroundColor};
		}
		header{
			height: 44px;
			position: sticky;			
			top: 0;
			z-index: 1000;				
			background-color: #3a6ea5 !important;
			padding-top: 10px;
			padding-left: 10px;
		}
		.pagetitle{
			textDecoration: none;
			color: #ffffff;
		}
		.pagetitle:hover{
			color: #ffffff;
		}
	  `;

	const pdfcss = `
	  	.mainArea{
			display:grid;
			grid-template-columns: 50vw 10px 1fr 10px;
			grid-template-rows: 40vw 1fr;
		}
		.slideArea{
			grid-column: 1/1;
			grid-row: 1/2;
		}
		.textArea{
			grid-column: 3/3;
			grid-row: 1/2;
		}
		.text-current{
			height: 40vh;
		}
		.text-next{
			height: 35vh;
		}
		.iframe_main{
			height: 40vh !important;
			width:  50vw !important;
			display: block;
		}
		.iframe_next{
			height: 35vh !important;
			width:  50vw !important;
			display: block;
		}
		.message:not(:last-child){
			margin-bottom: 5px;
		}
		.message-body{
			padding:0px;
		}
		#toolbarViewer{
			display:none;
		}
	  `;

	const pdfcss_view = `
	  	iframe{
			height: 100vh !important;
			width:  100% !important;
		}
	  `;

	// 更新
	const refresh = (page) => {
		setPage(page);
	}

	// ページ送りボタンを押したときの動作
	const handlePage = (p) => {
		setPage(p);
		getText(p);
		if (socketReady) {
			try {
				socket.current.send('{"page":"' + p + '","file":"' + file + '","mode":"pdf"}');
			} catch (error) {
				console.log(error);
			}
		}
	};

	// バックエンドのURL
	const url = "/app/cgi/pdf.cgi";

	// テキスト更新
	const getText = async (num) => {
		const sendPostStr = "file=" + file + "&page=" + num;
		const res = await fetch(url, { method: 'post', headers: { 'content-type': 'application/x-www-form-urlencoded' }, body: sendPostStr, credentials: 'include' });
		if (res.status == "200" || res.statustext == "ok") {
			const data = await res.text();
			setText(data);
		}
	}

	// ファイルリスト更新
	const getFileList = async () => {
		const sendPostStr = "dummy=1234";
		const url = "/app/cgi/pdflist.cgi";
		const res = await fetch(url, { method: 'post', headers: { 'content-type': 'application/x-www-form-urlencoded' }, body: sendPostStr, credentials: 'include' });
		if (res.status == "200" || res.statustext == "ok") {
			const data = await res.text();
			setPdfList(data);
		}
	}

	// テキスト取得
	const fetchText = async () => {
		const sendPostStr = "file=" + file + "&page=" + pageNumber;
		const res = await fetch(url, { method: 'post', headers: { 'content-type': 'application/x-www-form-urlencoded' }, body: sendPostStr, credentials: 'include' });
		if (res.status == "200" || res.statustext == "ok") {
			const data = await res.text();
			setText(data);
		}
	}

	// ページ読み込み時
	useEffect(() => {

		if (file != null) {
			document.title = `PDF Viewer & Presenter Tool (${file})`;
		} else {
			document.title = `PDF Viewer & Presenter Tool`;
		}

		if (!socketReady) {
			const socketURL = "wss://www.3naly.xyz/ws";
			const ws = new WebSocket(socketURL);
			socket.current = ws;
		}

		// WebSocketのEventListener
		socket.current.addEventListener("open", e => {
			console.log("Connection Open");
			setSocketState(true);
		});
		socket.current.addEventListener("close", e => {
			console.log("Connection Closed");
			setSocketState(false);
		});
		socket.current.addEventListener("message", e => {
			const wsjson = JSON.parse(e.data);
			try {
				if (mode != "presenter" && wsjson.file == file) refresh(wsjson.page);
			} catch (error) {
				console.log(error);
			}
		});

		if (mode == "presenter")
			fetchText();

	}, []);

	const [pdfFile, setPDF] = useState(null);
	const [textFile, setTxtFile] = useState(null);
	const [uploadResult, setResult] = useState("");
	const [pdffileName, setpdfName] = useState("Please select a file.");
	const [textfileName, settextName] = useState("Please select a file.");

	const onFileInputChangePdf = (e) => {
		setPDF(e.target.files[0]);
		setpdfName(e.target.files[0].name);
	};
	const onFileInputChangeText = (e) => {
		setTxtFile(e.target.files[0]);
		settextName(e.target.files[0].name);
	};

	// アップロード
	const uploadFile = async (num) => {
		document.getElementById("uploadBtn").disabled = true;
		setResult("アップロードしています...");
		const formData = new FormData();
		formData.append("PDF", pdfFile);
		formData.append("TEXT", textFile);
		const res = await fetch("/app/cgi/rcvpdf.cgi", { method: 'post', body: formData, credentials: 'include' });
		if (res.status == "200" || res.statustext == "ok") {
			const data = await res.json();
			if (data.status == "ok") {
				setResult(data.body);
			} else {
				setResult(data.body);
			}
			// getFileList();
			document.getElementById("uploadBtn").disabled = false;
		}
	}

	const dispChange = (n) => {
		switch (n) {
			case 0:
				return (
					<>
						<style>{pdfmain}</style>
						<header>
							<h5 className="title is-5"><a className="pagetitle" href="/pdf">PDF</a></h5>
						</header>
						<div style={{ padding: "15px", fontSize: "16px" }}>
							<h4 className="is-4 is-title mb-3"> Usage </h4>
							<ol style={{ marginLeft: "35px", marginBottom: "25px" }}>
								<li> PDFファイルを選択してください。 </li>
								<li> 台本用のテキストファイルもあれば選択してください。 </li>
								<li>「アップロード」を押してください。ファイルに問題がなければリンクが表示されます。
									<br />
									（同名のファイルは上書きされます。）
								</li>
							</ol>
							<div className="mt-4">
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div className="file is-small has-name mb-1">
										<label className="file-label">
											<input className="file-input" accept=".pdf" type="file" name="pdf" onChange={(e) => {
												onFileInputChangePdf(e);
											}} />
											<span className="file-cta">
												<span className="file-icon">
													<i className="fas fa-upload"></i>
												</span>
												<span className="file-label">
													PDF
												</span>
											</span>
											<span className="file-name">
												{pdffileName}
											</span>
										</label>
									</div>
									<br />
									<div className="file is-small has-name">
										<label className="file-label">
											<input className="file-input" type="file" name="pdf" onChange={(e) => {
												onFileInputChangeText(e);
											}} />
											<span className="file-cta">
												<span className="file-icon">
													<i className="fas fa-upload"></i>
												</span>
												<span className="file-label">
													txt
												</span>
											</span>
											<span className="file-name">
												{textfileName}
											</span>
										</label>
									</div>
								</div>
								<button id="uploadBtn" className="button is-primary is-small mt-5" onClick={() => {
									uploadFile();
								}}>
									<i className="fas fa-upload"></i>
									&nbsp;
									アップロード
								</button>
								<br />
								<span>{parse(uploadResult)}</span>
							</div>
						</div>
					</>
				)
			case 1:
				return (
					<div>
						<style>
							{pdfcss_view}
						</style>
						<iframe src={`${Path3}#page=${pageNumber}`} ></iframe>
					</div>
				);
			case 2:
				return (
					<div className="main" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
						<style>{pdfcss}</style>
						<div className="btnArea" style={{ marginBottom: "10px" }}>
							<button className="button is-primary" onClick={() => {
								handlePage(1);
							}
							}>&lt;&lt;</button>
							<button className="button is-primary ml-1" onClick={() => {
								handlePage(pageNumber - 1);
							}
							}>&lt; 前へ</button>
							<button className="button is-primary ml-1" onClick={() => {
								handlePage(pageNumber + 1);
							}
							}>次へ &gt;</button>
							<span className="button is-outlined is-primary is-4 ml-1" >{pageNumber}</span>
						</div>
						<div className="mainArea">
							<div className="slideArea">
								<article className="message is-primary current_slide">
									<div className="message-header">
										現在のスライド
									</div>
									<div className="message-body">
										<iframe className="iframe_main" src={`${Path1}#page=${pageNumber}`} ></iframe>
									</div>
								</article>
								<article className="message is-link next_slide">
									<div className="message-header">
										次のスライド
									</div>
									<div className="message-body">
										<iframe className="iframe_next" src={`${Path2}#page=${pageNumber + 1}`} ></iframe>
									</div>
								</article>
							</div>
							<div className="textArea">
								{parse(textHtml)}
							</div>
						</div>
					</div>
				);
			default:
				break;
		}
	};

	return (
		mode != "presenter" ? (
			!file ? (
				dispChange(0)
			) : (
				dispChange(1)
			)
		) : (
			dispChange(2)
		)
	);

}

export default PDF;
