import {useEffect, useState, useRef} from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll, scrollSpy } from 'react-scroll';
import Confirm from './Confirm';
import Table from './Table';
import Modal from './Modal';

const Main = ({user,sid,name,method}) => {

	  const [mode,setMode] = useState("0");
	  const [jsonData,setJson] = useState("");
	  const [msg,setMsg] = useState(""); 
	  const [selectedSlot, setSelectedSlot] = useState(null);
	  const [selectedData,setSelectedData] = useState(null);
	  const [selectedButton,setSelectedButton] = useState(255);

	  const [inputName,setName] = useState("");
	  const [inputEmail,setEmail] = useState("");
	  const [inputEmailConfirm,setEmailConfirm] = useState("");
	  const [inputTel,setTel] = useState("");
	  const [inputMethod,setMethod] = useState(null);
	  const [inputMsg,setInputMsg] = useState("");

	  // postURL
	  const postURL = (url, data) => {
	
		const form = document.createElement('form');
			form.method = 'POST';
			form.action = url;   

			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = "jsonData";
			input.value = JSON.stringify(data);
			form.appendChild(input);

			// フォームをドキュメントに追加し、送信
			document.body.appendChild(form);
			
			form.submit();
	  };

	  // handle export ics
	  const handleExportIcs = () => {
		delete inputObject.fetchedData.cal;
		postURL("/app/cgi/appt_export_ics.cgi",inputObject);
	  };

	  // handle export txt
	  const handleExportTxt = () => {
		delete inputObject.fetchedData.cal;
		postURL("/app/cgi/appt_export_txt.cgi",inputObject);
	  };

	  // 必須入力の入力状態
	  const handleRequireInput = () =>{
		if(inputName == "" || inputMethod == null || selectedData == null){
			return false;
		}else{
			return true;
		}
	  };

	  // input object
	  const inputObject = {
		 name: inputName,
		 email: inputEmail,
		 tel: inputTel,
		 method: inputMethod,
		 message: inputMsg,
		 data: selectedData,
		 fetchedData: jsonData
	  }; 

	  // ラジオボタンのハンドラ
	  const handleChange = (event) => {
		setMethod(event.target.value);
	  };

	  // Defining functions to perform different types of scrolling.
	  const scrollToTop = () => {
	    scroll.scrollToTop({
	      duration: 0,  // アニメーションなし
	      smooth: false // スムーズなスクロールを無効化
	    });
	  };

	  // アニメーション用のクラスを動的に設定
	  const getAnimationClass = () => {
	    return "fade-in";
	  };

	  // モードの設定
	  const handleSetMode = (m) =>{
	    setMode(m);
	  };

	  // 子コンポーネントからクリックイベントで呼ばれるコールバック関数
	  const handleSlotClick = (slotData) => {
		// 選択中の値とボタンをリセット
		setSelectedData(null);
		setSelectedButton(255);
		// クリックされたスロットの情報を保存
	    setSelectedSlot(slotData); 

	    // console.log('親コンポーネントで受け取った値:', slotData); // ここで値を受け取って処理できます
	  };

	  // ボタンを押したときの値
	  const handleTimeButton = (data,index) =>{
		// console.log('選択中の値:', data);
		setSelectedData(data);
		setSelectedButton(index);
	  };

	  // 予定の取得
	  const fetchSchedule = async() => {
          const url = "/app/cgi/appt_fetch.cgi";
	      const sendPostStr = "sid="+sid;
	      const res = await fetch(url,{method:'post',headers:{'Content-type':'application/x-www-htmlForm-urlencoded'},body:sendPostStr,credentials:'include'});
	      if (res.status == "200" || res.statustext == "ok"){
	     		const data = await res.json();
				setJson(data);
				setMsg(data.msg);
				if(data.removed == "0")
					document.title = data.title; 
	      }
	  };

	  // 予定(json)の検証
	  const validateSchedule = () => {
		if (jsonData.cal && jsonData.cal.length > 0){
			if(jsonData.removed != "1"){
				return(true);
			}
		}else{
			return(false);
		}
	  }

	  //メソッドの切り替え
	  const switchMethod = (m) =>{
			switch(m){
				case "1":
					setMethod("Jitsi Meet");
					break;
				case "2":
					setMethod("Zoom");
					break;
				case "3":
					setMethod("Discord");
					break;
				case "4":
					setMethod("その他");
					break;
			}
	  };

	  // 表示内容の切り替え
	  const dispChange = () =>{
		switch(mode){
			case "0":
				return(
				<div>
					<div style={{margin:"40px 15px 40px 15px"}}>
						<p>
							{msg}	
						</p>
					</div>
					<h4 className="mt-2 ml-2">① 時間帯を選択してください</h4>
					<div style={{margin:"15px 15px 25px 15px"}}>
						<p>
							<span>色のついた場所をクリックすると予約可能な時間が表示されます。</span><br />
							<span className="has-text-primary has-background-primary">__</span> 予約可能な時間があります。 
						</p>
					</div>
					<div id="time_scroll1" style={{height:"0px;"}}></div>
					<div id="time_scroll2" style={{height:"0px;"}}></div>
					{/* 時間帯選択テーブル */}
					{validateSchedule() ? (
						<Table data={jsonData} onSlotClick={handleSlotClick} />
					 ) : (
					    <p>データを読み込み中...</p>
					 )}
					
					{/* 時間帯選択テーブル */}
					{/*時間選択*/}
					<div className="timeSelect" id="timeSelect">
						<h4 className="timeSelect_title">② 時間を1つ選択してください<span className="has-text-danger">*</span></h4>
						<div className="selectTime">
							{selectedSlot != null ? (
								<>
								{selectedSlot.filteredCal.map((data, index) => (
									<button 
										className={`button is-primary ${selectedButton === index ? "" : "is-outlined"}`} 
										onClick={() => handleTimeButton(data,index)}>
											{data.date_disp2}&nbsp;{data.time_from_disp} ~ {data.time_to_disp}
									</button>
								))}
									<ScrollLink to="time_scroll1" smooth={true} delay={0} duration={800} offset={-200}>
										<button className="button is-light is-info" style={{width:"220px"}} >
										⇧上に戻る 
										</button>
									</ScrollLink>
								</>
							):(
								<div>時間帯を選択するとここにボタンが表示されます</div>
							)}
						</div>
					</div>
					{/*時間選択*/}
					<h4 className="mt-5 ml-2">③ お名前や連絡手段を記入してください</h4>
					{/*質問*/}
					<div style={{margin:"15px"}}>
						<label className="iptlabel" htmlFor="onamae">お名前<span className="has-text-danger">*</span></label>
						<input type="text" onChange={(e) => setName(e.target.value)} value={inputName} id="onamae" className="input question" placeholder="名前を入力してください" />
						<br />
						<br />
						<label className="iptlabel" htmlFor="email">メールアドレス</label>
						<input type="text" onChange={(e) => setEmail(e.target.value)} value={inputEmail} id="email" className="input question" placeholder="メールアドレスを入力してください" />
						<br />
						<br />
						{/* <label className="iptlabel" htmlFor="email_confirm">メールアドレスの確認入力<span className="has-text-danger">*</span></label> */}
						{/* <input type="text" onChange={(e) => setEmailConfirm(e.target.value)} value={inputEmailConfirm} id="email_confirm" className="input question" placeholder="メールアドレスをもう一度入力してください" /> */}
						{/* <br /> */}
						{/* <br /> */}
						<label className="iptlabel" htmlFor="tel">電話番号</label>
						<input type="text" onChange={(e) => setTel(e.target.value)} id="tel" value={inputTel} className="input question" placeholder="電話番号を入力してください" />
						<br />
						<br />
						<label className="" htmlFor="method">コミュニケーションの手段<span className="has-text-danger">*</span></label>
						<br />
						<label className="radio" htmlFor="jitsi" ><input type="radio" id="jitsi" name="answer" value="Jitsi Meet"
          				checked={inputMethod === 'Jitsi Meet'}
          				onChange={handleChange}/> Jitsi Meet (<b>おすすめ</b>)</label>
						<br />
						<label className="radio" htmlFor="zoom" ><input type="radio" id="zoom" name="answer" value="Zoom"
          				checked={inputMethod === 'Zoom'}
          				onChange={handleChange}/>  Zoom (あとでリンクを送ります)</label>
						<br />
						<label className="radio" htmlFor="discord"><input type="radio" id="discord" name="answer" value="Discord"
          				checked={inputMethod === 'Discord'}
          				onChange={handleChange}/>  Discord </label>
						<br />
						<label className="radio" htmlFor="other"><input type="radio" id="other" name="answer" value="その他"
          				checked={inputMethod === 'その他'}
          				onChange={handleChange}/>  その他 (メッセージ欄に書いてください)

						</label>
						<br />
						<br />
						<label className="" htmlFor="mesg">メッセージ</label>
						<textarea
						  className="textarea mb-5"
						  placeholder="メッセージを記入してください"
						  rows="5"
						  onChange={(e) => setInputMsg(e.target.value)}
						  value={inputMsg}
						></textarea>
					</div>
					{/*質問*/}
					{/*確認ボタン*/}
					<footer className="confirmArea">
						{ handleRequireInput() ? (
							<button className="button btn_confirm is-primary" 
							 onClick={() => handleSetMode("1")}>
								確認画面に進む
							</button>
						):(
							<button className="button btn_confirm is-primary" disabled> 
								確認画面に進む
							</button>
						)}
					</footer>
					{/*確認ボタン*/}
				</div>
				);

			case "1":
				scrollToTop();
				return(
				<div className={getAnimationClass()}>
					{/*予約確認画面*/}
					<Confirm data={inputObject} />
					{/*予約確認画面*/}
					{/*確認ボタン*/}
					<footer className="confirmArea">
						<button className="button btn_confirm is-light" onClick={() => handleSetMode("0")}>前の画面に戻る</button>
						<button className="button btn_confirm is-primary" onClick={() => handleSetMode("2")}>予約を完了する</button>
					</footer>
					{/*確認ボタン*/}
				</div>
				);

			case "2":
				scrollToTop();
				return(
				<div className={getAnimationClass()}>
					<Modal sid={sid} data={inputObject} />
					{/*確認ボタン*/}
					<footer className="confirmArea">
						<button style={{width:"45%"}} className="button btn_confirm is-light"
						    onClick={handleExportTxt}>
							<small>
								テキストでダウンロード
							</small>
						</button>
						<button style={{width:"45%"}} className="button btn_confirm is-primary"
							onClick={handleExportIcs} >
							<small>
								カレンダーに登録
							</small>
						</button>
					</footer>
					{/*確認ボタン*/}
				</div>
				);
		}
	  };

	  // ページ読み込み時
	  useEffect(() => {
		fetchSchedule();
	  	handleSetMode("0");

		if(name.length != 0){
			setName(name);
		}

		if(method.length != 0){
			switchMethod(method);
		}

	  },[]);


	return(
		<>
			{ validateSchedule() ? (
			<>
				<header className="mb-5">
					<h3 className="title is-4 has-text-white"><a href={`/appt?sid=${sid}`} style={{textDecoration:"none",color:"#FFF"}}>
						<div>{jsonData.title}</div>
					</a></h3>
				</header>
				{dispChange()}
			</>
			):(
				<div style={{height:"100vh"}}>
					{/*このスケジュールは削除または予約済みです。*/}
				</div>
			)
		  }
		</>
	)

}

export default Main;
